import React from 'react'
import { ReactTyped } from "react-typed";

const Hero = () => {
  return (
    <div className='text-white bg-[url("./Assets/back2.png")]'>
        <div className='max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
            <h1 className = "text-[#fad744] md:text-7xl sm:text-gxl text-4xl font-bold md:py-6">Nurturing Your Programming Journey.</h1>
            <div className='flex justify-center'>
                <h1 className='md:text-5xl sm:4xl text-xl font-bold text-[#ef5455]'>
                 <span>System.out.print(</span></h1>
                <ReactTyped
                 className='md:text-5xl sm:4xl text-xl font-bold pl-2 text-[#fad744]' 
                 strings={['"Hello World"']}
                  typeSpeed={120} 
                  backSpeed={140} 
                  loop/>
                  <p className='md:text-5xl sm:4xl text-xl font-bold text-[#ef5455]'>
                 <span>)</span></p>

            </div>
            <p className = "text-[#fad744] font-bold p-2 md:tex-2xl text-xl">Empowering Innovators of Tomorrow with Java</p>
        </div>



    </div>
  )
}

export default Hero