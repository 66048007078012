import React from 'react';
import image from '../Assets/guy.jpg';

const About = () => {
  return (
    <div className='w-full bg-white py-16 px-4'>
      <div className='max-w-[1240px] mx-auto flex flex-col md:flex-row items-center justify-center md:px-8'>
        <img className="w-[700px] h-auto md:mr-8" src={image} alt='/' />
        <div className="md:ml-8">
          <p className='text-[#2b3252] font-bold'>ABOUT US</p>
          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>Empowering the Next Generation of Programmers with Java</h1>
          <p>At JAVAFORJUNIORS, we are passionate about unlocking 
            the potential of young minds through the 
            power of programming. Founded on the principle
            that learning to code should be accessible, 
            engaging, and fun, our platform offers interactive 
            Java tutorials tailored specifically for kids and 
            teenagers. With a curriculum designed by experienced 
            educators and software developers, our courses blend
            foundational coding concepts with hands-on projects,
            ensuring students not only learn Java syntax but 
            also how to think like programmers.</p>
            <h1 className ='text-[#2b3252] font-bold'>Coming Soon!</h1>
        </div>
      </div>
    </div>
  )
}

export default About;
