import React,{useState} from 'react'
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai"
import logo from "../Assets/logo3.png"
const Navbar = () => {

  const[nav,setNav] = useState(true)

  const handleNav = () => {

    setNav(!nav)
  }

  return (
    <div className="flex justify-between items-center h-24 max-w mx-auto px-4  sticky top-0 bg-[#ef5455]">
      <h1 className="w-full text-3xl font-bold text-[#fad744] flex justify-center">
        <img src={logo} alt="Logo" className=""></img>
      </h1>

      <div onClick={handleNav} className="block md:hidden">
        {!nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>

      <div
        className={
          !nav
            ? "fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#fad744] ease-in-out duration-500"
            : "fixed left-[-100%]"
        }
      >
        <h1 className="w-full text-3xl font-bold text-[#ef5455] m-4">
          JAVA.FOR.JUNIORS
        </h1>
        <ul className="pt-12 uppercase p-4">
          <li className="p-4 border-b border-gray-600">Home</li>
          <li className="p-4 border-b border-gray-600">About</li>
          <li className="p-4 border-b border-gray-600">Courses</li>
          <li className="p-4 border-b border-gray-600">Resources</li>
        </ul>
      </div>
    </div>
  );
}

export default Navbar
